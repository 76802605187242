.testimonialbutton {
    padding: 10px 20px;
    background-color: #0056b3;
    margin: 10px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
  }
 
  .testimonial-form {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
 
  .form-group {
    margin-bottom: 15px;
  }
 
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
 
  input,
  textarea {
    width: 100%;
    padding: 8px;
    margin-top: 3px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
 
  .testcss {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
  .testcss:hover {
    background-color: #45a049;
  }
 
  .success-message {
    color: #4caf50;
    margin-top: 10px;
  }
 
  .error-message {
    color: #f44336;
    margin-top: 10px;
  }