.list-group-item:hover{
    background-color: lightgray;
    color: black;
    border-radius: 5px;
}
.list-group-item.active {
    /* color: #ffffff; */
    background-color: #0c0c37;
    color: white;
    border-radius: 5px;
}