/* UserData */

.popUpheading {
  background-color: rgb(132, 0, 255);
  color: #fff;
  font-size: xx-large;
  padding: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.popHeading {
  font-size: x-large;
  border-radius: 2px solid black;
  margin-top: 20px;
  margin-bottom: 20px;
}
.custom-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.custom-card {
  cursor: pointer;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-weight: bold;
  color: #333;
  width: calc(33.33% - 30px);
  box-sizing: border-box;
}

.popup-overlay {
  position: absolute;
  top: 80px;
  left: center;
  width: 100%;
  height: 100%;
  
}

.three-table{
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
}

.popup1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  overflow-y: auto;
  box-sizing: border-box;
}

.popup1 h3 {
  color: #007bff;
}

.popup1 ul {
  list-style-type: none;
  padding: 0;
}

.popup1 li {
  margin-bottom: 10px;
  margin-left: 10px;
}

.popup1 button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup1 button:hover {
  background-color: #0056b3;
}


@media (max-width: 767px) {
  .custom-card {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .popup1 {
    width: 100%;
  }
}

/* PendingWork */



.heading {
  text-align: left;
  color: #007bff;
  margin-bottom: 20px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.custom-table th {
  background-color: #007bff;
  color: #fff;
}

.custom-table td:first-child {
  background-color: #f9f9f9;
}

.custom-table td:nth-child(2) {
  background-color: #ffe8a1;
}

.custom-table td:nth-child(3) {
  background-color: #b5e3b5;
}

.custom-table td:nth-child(4) {
  background-color: #ffb6b6;
}

@media (max-width: 767px) {
  .custom-table {
    margin-top: 10px;
  }

  .custom-table th,
  .custom-table td {
    padding: 10px;
  }

  .heading {
    margin-bottom: 10px;
  }
}

.card-container {
  display: grid;
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-weight: bold;

  margin: 10px 0;
}

@media (min-width: 768px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

 Email 
  .history{
    margin: 20px;
  }
 
  
  
  
  
  
  
  
  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
  }
  
 
  
  /* button:hover {
    background-color: black;
    color: white;
  } */
  


.card-container {
  display: grid;
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-weight: bold;
}

@media (min-width: 768px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .card-container {
    grid-template-columns: 1fr;
  }
}
