.login-form {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.password{
  position: relative;
}
.eye_container{
 position: absolute;
 right: 20px;
 top: 2.6rem;
 cursor: pointer;
}